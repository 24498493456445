import React from 'react'
import {Link} from 'react-router-dom'
import {AppContextConsumer} from '/src/components/Context/appContext'



class HeaderLink extends React.Component {

  render() {
    return (

      <AppContextConsumer>
        {context => (
          <Link to={this.props.to} className={this.props.customClassName} onClick={() => {context.forceUnsetActiveNav(); context.closeMenuMobile();}}><span>{this.props.title}</span></Link>
        )}
      </AppContextConsumer>

    )
  }
}


export default HeaderLink
