import { PureComponent } from "react";
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import { withRouter } from '/src/components/Helpers/withRouter'

/* WHEN CHANGING PAGE ROUTE */

class WhenChangeRoute extends PureComponent {

  static contextType = AppContext;

  /* Scroll to Top */
  componentDidMount = () => {
    //window.scrollTo(0, 0);
    this.context.closeSearch();
  }

  componentDidUpdate = prevProps => {
    //if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
  };

  render = () => this.props.children;
}

export default withRouter(WhenChangeRoute);
