export default [
  {
    key: 1,
    title: 'Service',
    submenu: [
      {
        key: 0,
        title: 'Contact',
        to: '/service/contact'
      },
      {
        key: 1,
        title: 'Delivery information',
        to: '/service/delivery'
      },
      {
        key: 2,
        title: 'Returns & exchanges',
        to: '/service/returns'
      },
      {
        key: 3,
        title: 'FAQ',
        to: '/service/faq'
      },
      {
        key: 4,
        title: 'Payment options',
        to: '/service/payment'
      },
      {
        key: 5,
        title: 'Size conversion',
        to: '/service/size'
      },
      {
        key: 6,
        title: 'Material guide',
        to: '/service/materials'
      },
      {
        key: 7,
        title: 'Garmet care',
        to: '/service/care'
      }
    ]
  },
  {
    key: 2,
    title: 'Company',
    submenu: [
      {
        key: 0,
        title: 'About',
        to: '/'
      },
      {
        key: 1,
        title: 'Press',
        to: '/press'
      },
      {
        key: 2,
        title: 'Careers',
        to: '/careers'
      },
      {
        key: 3,
        title: 'Store locator',
        to: '/stores'
      }
    ]
  },
  {
    key: 3,
    title: 'Legal',
    submenu: [
      {
        key: 0,
        title: 'Terms & Conditions',
        to: '/legal/terms'
      },
      {
        key: 1,
        title: 'Cookie policy',
        to: '/legal/cookiepolicy'
      },
      {
        key: 2,
        title: 'Privacy policy',
        to: '/legal/privacy'
      }
    ]
  }
]
