import React from 'react'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import {Link} from 'react-router-dom'
import Headroom from 'react-headroom'
import HeaderMobile from './HeaderMobile'
import HeaderHeader from './HeaderHeader'
import HeaderSearch from './HeaderSearch'
import HeaderNavProjects from './HeaderNavProjects'
import HeaderNavAbout from './HeaderNavAbout'
import HeaderNavExplore from './HeaderNavExplore'
import HeaderNavAccount from './HeaderNavAccount'
import HeaderNavService from './HeaderNavService'
import HeaderNavBag from './HeaderNavBag'


class Header extends React.Component {

  static contextType = AppContext;

  handleResize = e => {
    this.saveSizeVariables();
    return;
  };

  saveSizeVariables = () => {
    const windowHeight = window.innerHeight;
    document.documentElement.style.setProperty('--window-height', windowHeight + 'px');
    document.documentElement.style.setProperty('--vh', (windowHeight > 900 ? 9 : windowHeight/100) + 'px');

    const windowWidth = window.innerWidth;
    document.documentElement.style.setProperty('--window-width', windowWidth + 'px');
    document.documentElement.style.setProperty('--vw', (windowWidth < 835 ? 12 : windowWidth > 1440 ? 14.4 : windowWidth/100) + 'px');

    const promoBanner = document.getElementsByClassName('overlay-promobanner')[0];
    const promoBannerHeight =  promoBanner ? promoBanner.clientHeight : 0;
    document.documentElement.style.setProperty('--promobanner-height', promoBannerHeight + 'px');
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.saveSizeVariables();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }



  render() {
    return (

      <React.Fragment>



        <div className={`header-background ${(this.context.state.mobileMenuIsOpen || this.context.state.activeNavMobile === 'bag') ? `is-on` : ``}`}>
          <div className="header-background-close" onClick={this.context.closeMenuMobile}></div>
          <div className="header-background-bg"></div>
        </div>


        <Headroom>
          <AppContextConsumer>
            {context => (
              <header>

                <HeaderHeader />

              </header>
            )}
          </AppContextConsumer>
        </Headroom>


        <HeaderNavProjects />

        <HeaderNavAbout />

        <HeaderNavExplore />

        <HeaderNavAccount />

        <HeaderNavService />

        <HeaderNavBag />


      </React.Fragment>

    )
  }
}


export default Header
