import React from 'react'
import {Link} from 'react-router-dom'
import { withRouter } from '../Helpers/withRouter'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'

/* DEPRECTAED */
/* moved to search page */

class HeaderSearch extends React.Component {

  static contextType = AppContext;

  state = {
    isSearching: false
  }

  handleChange = (event) => {


    this.setState({ isSearching: event.target.value.length > 0 ? true : false });
    if (event.target.value.length > 10) {
      this.props.navigate('/search/empty');
    } else if (event.target.value.length > 0) {
      this.props.navigate('/search');
    }

    this.context.setSearchTerm(event.target.value);

  }

  render() {

    return (
      <div id="search-form" className={`header-search ${(this.context.state.searchIsOpen) ? `is-on` : ``}`}>
        <div className="header-search-inner">
          <input type="text" className="input-search-display" placeholder="Enter search term..." onChange={this.handleChange} />
          
        </div>
      </div>
    )
  }
}

export default withRouter(HeaderSearch)
