import React from 'react'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import {Link} from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import HeaderLink from './HeaderLink'
import HeaderIconBack from './HeaderIconBack'


class HeaderNavService extends React.Component {

  static contextType = AppContext;


  render() {

    return (
      <nav
        id="nav-service"
        className={`nav nav-secondary ${(this.context.state.activeNav === 'service') ? `is-on` : ``}  ${(this.context.state.activeNavMobile === 'service') ? `is-on-mobile` : ``} ${(this.context.state.mobileMenuIsClosing === 'service') ? `is-closing-mobile` : ``}`}
        onMouseEnter={isMobile ? false : () => this.context.setActiveNavInner('service')}
        onMouseLeave={isMobile ? false : this.context.unsetActiveNavInner}
        >

        <div className="nav-layout nav-layout-horizontal nav-layout-secondary">

          <div className="nav-layout-item">
            <ul className="nav-menu nav-menu-contact">
              {/*<li className="hd is-only-desktop">Contact</li>*/}
              <li><Link to="/" onClick={() => {this.context.forceUnsetActiveNav(); this.context.closeMenuMobile();}}><span>+46 85809 7914</span></Link></li>
              <li><Link to="/" onClick={() => {this.context.forceUnsetActiveNav(); this.context.closeMenuMobile();}}><span>E-mail</span></Link></li>
              <li><Link to="/" onClick={() => {this.context.forceUnsetActiveNav(); this.context.closeMenuMobile();}}><span>Chat <span className="tooltip">online now</span></span></Link></li>
            </ul>
          </div>

          <div className="nav-layout-item">
            <ul className="nav-menu">
              {/*<li className="hd is-only-desktop">May we help?</li>*/}
              <li><HeaderLink to="/service/contact" title="Contact" /></li>
              <li><HeaderLink to="/service/delivery" title="Delivery" /></li>
              <li><HeaderLink to="/service/returns" title="Returns" /></li>
              <li><HeaderLink to="/service/faq" title="FAQ" /></li>
              <li><HeaderLink to="/service/payment" title="Payment options" /></li>
              <li><HeaderLink to="/service/size" title="Size conversion" /></li>
              <li><HeaderLink to="/service/materials" title="Material guide" /></li>
              <li><HeaderLink to="/service/care" title="Garment care" /></li>
              <li><HeaderLink to="/stores" title="Store locator" /></li>
            </ul>
          </div>

          <HeaderIconBack />

        </div>
      </nav>

    )
  }
}

export default HeaderNavService
