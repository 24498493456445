import React from 'react'
import {Link} from 'react-router-dom'
import Plx from 'react-plx'
import Typewriter from 'typewriter-effect';

import indexGridData from '../Index/indexData'

/* PLX

  More options:
  https://www.npmjs.com/package/react-plx

*/

/* TYPEWRITER

  https://www.npmjs.com/package/typewriter-effect

*/


let currentLetter = "";
let previousLetter = "";


class HomeIndex extends React.Component {

  constructor(props) {
    super(props);
    this.indexRef = React.createRef();
    this.indexSecondaryRef = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      scrollRatio: 5,
      test: "test state",
      indexElTop: 0,
      indexElBottom: 0,
      indexElHeight: 0,
      indexSecondaryElHeight: 0,
    }
  }




  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.saveIndexVariables();

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  }


  handleResize = e => {
    this.saveIndexVariables();
    return;
  };

  saveIndexVariables = () => {
    const indexElRect = this.indexRef.current.getBoundingClientRect();
    const indexSecondaryElRect = this.indexSecondaryRef.current.getBoundingClientRect();
    this.setState({
      indexElTop: indexElRect.top,
      indexElBottom: indexElRect.bottom,
      indexElHeight: indexElRect.height,
      indexSecondaryElHeight: indexSecondaryElRect.height
      });
  }


  handleScroll(event) {

    let scrollTop = window.pageYOffset;

    let scrollRatio = scrollTop < this.state.indexElTop
      ? 0
      : scrollTop > (this.state.indexElBottom - this.state.indexSecondaryElHeight)
        ? 1
        : (window.pageYOffset - this.state.indexElTop) / (this.state.indexElHeight - this.state.indexSecondaryElHeight);

    document.documentElement.style.setProperty('--scroll', scrollRatio);

    /*
    console.log('scrollRatio',scrollRatio);
    console.log('scrollTop',scrollTop);

    console.log('state.indexElTop', this.state.indexElTop);
    console.log('state.indexElBottom', this.state.indexElBottom);
    */

    //console.log('scrollTop', window.pageYOffset);


    /*
    this.setState({
      transform: itemTranslate
    });
    */
  }

  // Return ProductTiles
  articleTileComponents = indexGridData.map(projectItem => {
    previousLetter = currentLetter;
    currentLetter = projectItem.title[0];

    const letterSignifier = previousLetter != currentLetter ? <div className="index-list-letter">{currentLetter}</div> : ``;

    return (
      <>
        {letterSignifier}

        <div className="index-list-tile" key={projectItem.key}>

          <Link to={`/article/${projectItem.key}`}>
            <div className="index-list-tile-image">
              <img src={projectItem.previewImage} />
              <div className="index-list-tile-image-subtitle"><span>({projectItem.data.subTitle})</span></div>
            </div>

            <div className="projects-list-tile-caption">
              <div className="index-list-tile-title"><span>{projectItem.title}</span></div>
              <div className="index-list-tile-subtitle"><span>{projectItem.data.subTitle}</span></div>
            </div>
          </Link>
        </div>
      </>
    )
  })


  render() {


    return (

      <div className="fm-index" ref={this.indexRef}>


        <div className="index-list">
          {this.articleTileComponents}
        </div>

        <div className="index-secondary" ref={this.indexSecondaryRef}>
          <div className="index-wheel">
            <img src="/img/fm-index-wheel-wheel.png" className="index-wheel-wheel" />
            <img src="/img/fm-index-wheel-arrow.png" className="index-wheel-arrow" />
          </div>
          <div className="index-wheel-caption">
            (the wheel of fortune)
          </div>
        </div>

      </div>


    )
  }
}

export default HomeIndex
