import React from 'react'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import {Link} from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import HeaderLink from './HeaderLink'
import HeaderIconBack from './HeaderIconBack'


class HeaderNavAccount extends React.Component {

  static contextType = AppContext;


  render() {

    return (
      <nav
        id="nav-account"
        className={`nav nav-secondary ${(this.context.state.activeNav === 'account') ? `is-on` : ``}  ${(this.context.state.activeNavMobile === 'account') ? `is-on-mobile` : ``} ${(this.context.state.mobileMenuIsClosing === 'account') ? `is-closing-mobile` : ``}`}
        onMouseEnter={isMobile ? false : () => this.context.setActiveNavInner('account')}
        onMouseLeave={isMobile ? false : this.context.unsetActiveNavInner}
        >

        <div className="nav-layout nav-layout-horizontal nav-layout-secondary">

          <div className="nav-layout-item">
            <ul className="nav-menu">
              <li><HeaderLink to="/login" title="Sign in" /></li>
              <li><HeaderLink to="/register" title="Register" /></li>
            </ul>
          </div>

          <div className="nav-layout-item">
            <ul className="nav-menu">
              <li><HeaderLink to="/account" title="Overview" /></li>
              <li><HeaderLink to="/account/wishlist" title="Wishlist" /></li>
              <li><HeaderLink to="/account/profile" title="Account details" /></li>
              <li><HeaderLink to="/account/addressbook" title="Address book" /></li>
              <li><HeaderLink to="/account/orders" title="Orders & returns" /></li>
              <li><HeaderLink to="/account/payments" title="Saved cards" /></li>
            </ul>
          </div>

          <HeaderIconBack />

        </div>
      </nav>

    )
  }
}

export default HeaderNavAccount
