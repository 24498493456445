export default [
  {
    key: 100,
    title: 'Sweden',
    currency: 'SEK',
    area: 'europe',
    isActive: true
  },
  {
    key: 0,
    title: 'Aland Islands',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 1,
    title: 'Andorra',
    currency: 'USD',
    area: 'europe',
    isActive: false
  },
  {
    key: 2,
    title: 'Australia',
    currency: 'USD',
    area: 'oceania',
    isActive: false
  },
  {
    key: 3,
    title: 'Austria',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 4,
    title: 'Bahrain',
    currency: 'USD',
    area: 'middleeast',
    isActive: false
  },
  {
    key: 5,
    title: 'Bangladesh',
    currency: 'USD',
    area: 'asia',
    isActive: false
  },
  {
    key: 6,
    title: 'Belarus',
    currency: 'USD',
    area: 'europe',
    isActive: false
  },
  {
    key: 7,
    title: 'Belgium',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 8,
    title: 'Bosnia and Herzegovina',
    currency: 'USD',
    area: 'europe',
    isActive: false
  },
  {
    key: 9,
    title: 'Bulgaria',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 10,
    title: 'Canada',
    currency: 'USD',
    area: 'americas',
    isActive: false
  },
  {
    key: 11,
    title: 'Cayman Islands',
    currency: 'USD',
    area: 'americas',
    isActive: false
  },
  {
    key: 12,
    title: 'Chile',
    currency: 'USD',
    area: 'americas',
    isActive: false
  },
  {
    key: 13,
    title: 'China',
    currency: 'CNY',
    area: 'asia',
    isActive: false
  },
  {
    key: 14,
    title: 'Croatia',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 15,
    title: 'Cyprus',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 16,
    title: 'Czech Republic',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 17,
    title: 'Denmark',
    currency: 'DKK',
    area: 'europe',
    isActive: false
  },
  {
    key: 18,
    title: 'Estonia',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 19,
    title: 'Faroe Islands',
    currency: 'DKK',
    area: 'europe',
    isActive: false
  },
  {
    key: 20,
    title: 'Finland',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 21,
    title: 'France',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 22,
    title: 'Germany',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 23,
    title: 'Gibraltar',
    currency: 'USD',
    area: 'europe',
    isActive: false
  },
  {
    key: 24,
    title: 'Greece',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 25,
    title: 'Greenland',
    currency: 'DKK',
    area: 'americas',
    isActive: false
  },
  {
    key: 26,
    title: 'Guernsey',
    currency: 'USD',
    area: 'europe',
    isActive: false
  },
  {
    key: 27,
    title: 'Hong Kong',
    currency: 'HKD',
    area: 'asia',
    isActive: false
  },
  {
    key: 28,
    title: 'Hungary',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 29,
    title: 'Iceland',
    currency: 'USD',
    area: 'europe',
    isActive: false
  },
  {
    key: 30,
    title: 'India',
    currency: 'USD',
    area: 'asia',
    isActive: false
  },
  {
    key: 31,
    title: 'Indonesia',
    currency: 'USD',
    area: 'asia',
    isActive: false
  },
  {
    key: 32,
    title: 'Ireland',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 33,
    title: 'Italy',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 34,
    title: 'Japan',
    currency: 'JPY',
    area: 'asia',
    isActive: false
  },
  {
    key: 35,
    title: 'Jersey',
    currency: 'GBP',
    area: 'europe',
    isActive: false
  },
  {
    key: 36,
    title: 'Korea, Republic of',
    currency: 'KRW',
    area: 'asia',
    isActive: false
  },
  {
    key: 37,
    title: 'Kuwait',
    currency: 'USD',
    area: 'asia',
    isActive: false
  },
  {
    key: 38,
    title: 'Latvia',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 39,
    title: 'Liechtenstein',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 40,
    title: 'Lithuania',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 41,
    title: 'Luxembourg',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 42,
    title: 'Macedonia, the former Yugoslav Republic',
    currency: 'USD',
    area: 'europe',
    isActive: false
  },
  {
    key: 43,
    title: 'Malaysia',
    currency: 'USD',
    area: 'asia',
    isActive: false
  },
  {
    key: 44,
    title: 'Malta',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 45,
    title: 'Mexico',
    currency: 'USD',
    area: 'americas',
    isActive: false
  },
  {
    key: 46,
    title: 'Moldova, Republic of',
    currency: 'USD',
    area: 'europe',
    isActive: false
  },
  {
    key: 48,
    title: 'Montenegro',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 49,
    title: 'Netherlands',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 50,
    title: 'New Zealand',
    currency: 'USD',
    area: 'oceania',
    isActive: false
  },
  {
    key: 51,
    title: 'Norway',
    currency: 'NOK',
    area: 'europe',
    isActive: false
  },
  {
    key: 52,
    title: 'Poland',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 53,
    title: 'Portugal',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 54,
    title: 'Qatar',
    currency: 'USD',
    area: 'middleeast',
    isActive: false
  },
  {
    key: 55,
    title: 'Romania',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 56,
    title: 'Saudi Arabia',
    currency: 'USD',
    area: 'middleeast',
    isActive: false
  },
  {
    key: 57,
    title: 'Serbia',
    currency: 'USD',
    area: 'europe',
    isActive: false
  },
  {
    key: 58,
    title: 'Singapore',
    currency: 'USD',
    area: 'asia',
    isActive: false
  },
  {
    key: 59,
    title: 'Slovakia',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 60,
    title: 'Slovenia',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 61,
    title: 'South Africa',
    currency: 'USD',
    area: 'africa',
    isActive: false
  },
  {
    key: 62,
    title: 'Spain',
    currency: 'EUR',
    area: 'europe',
    isActive: false
  },
  {
    key: 63,
    title: 'Switzerland',
    currency: 'CHF',
    area: 'europe',
    isActive: false
  },
  {
    key: 64,
    title: 'Taiwan',
    currency: 'USD',
    area: 'asia',
    isActive: false
  },
  {
    key: 65,
    title: 'Thailand',
    currency: 'USD',
    area: 'asia',
    isActive: false
  },
  {
    key: 66,
    title: 'Turkey',
    currency: 'EUR',
    area: 'middleeast',
    isActive: false
  },
  {
    key: 67,
    title: 'Ukraine',
    currency: 'USD',
    area: 'europe',
    isActive: false
  },
  {
    key: 68,
    title: 'United Arab Emirates',
    currency: 'USD',
    area: 'middleeast',
    isActive: false
  },
  {
    key: 69,
    title: 'United Kingdom',
    currency: 'GBP',
    area: 'europe',
    isActive: false
  },
  {
    key: 70,
    title: 'United States',
    currency: 'USD',
    area: 'americas',
    isActive: false
  },
  {
    key: 71,
    title: 'Viet Nam',
    currency: 'USD',
    area: 'asia',
    isActive: false
  }
]
