import React from 'react'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import {Link} from 'react-router-dom'
import HeaderLink from './HeaderLink'
import {isMobile} from 'react-device-detect';

import Modal from 'react-modal';
import ModalMenu from '/src/components/Modal/ModalMenu'

/*

Using this to fire mouseEnter only on non-mobile devices:
https://www.reddit.com/r/reactjs/comments/arggf9/prevent_onmouseenter_event_from_firing_on_mobile/

*/

class HeaderMenu extends React.Component {

  static contextType = AppContext;

  state = {
    sublevel: false,
    modalMenuIsOpen: false
  }

  openMenuModal = () => {
    this.context.openMenuModal();
  }

  closeMenuModal = () => {
    this.context.closeMenuModal();
  }

  toggleMenuModal = () => {
    this.context.toggleMenuModal();
  }

  toggleSublevel = (sublevel) => {
    if (window.innerWidth < 835) {
      this.setState(prevState => ({ sublevel: prevState.sublevel === sublevel ? false : sublevel }));
    }
  }

  hideSublevel = () => {
    this.setState({ sublevel: false });
  }

  /* We probably dont need this anymore? / Solve it different... */
  resetSublevel = () => {
    setTimeout(() => {
      this.setState({ sublevel: false });
    }, 900)
  }


  render() {

    const MenuLink = (props) => {
      return (
        <HeaderLink resetSublevel={this.resetSublevel} to={props.to} title={props.title} customClassName={props.customClassName} />
      )
    }

    const breadcrumbs = this.context.state.fmNavLevel1
      ? <div><ul className={`${this.context.state.fmNavLevel2 ? `has-two-levels` : `has-one-level`}`}><li><Link to="/"><span>Index</span></Link></li>{this.context.state.fmNavLevel2 ? <li><Link to="/"><span>Article</span></Link></li> : ``}</ul></div>
      : <span>D+D</span>;

    return (

      <div id="menu" className="">

        <Modal
          isOpen={this.context.state.modalMenuIsOpen}
          onRequestClose={this.closeMenuModal}
          overlayClassName="overlay overlay-menu"
          bodyOpenClassName="ReactModal__Body--open ReactModal__Menu__Body--open"
          className="overlay-panel"
          closeTimeoutMS={0}
        >
          <div className="overlay-panel-inner">
            <ModalMenu closeModal={this.closeMenuModal} />
          </div>
        </Modal>

        <div className="menu-inner">

          <div className={`header-item-breadcrumbs`}>
            {/*
            {breadcrumbs}
            */}
          </div>


          <div className={`header-logo ${(this.context.state.activeNav) ? `is-on` : ``}`}>
            <Link to="/" onClick={this.context.closeMenuMobile}>
            </Link>
          </div>

          {/*
          <div className={`header-item-menu ${(this.context.state.activeNav) ? `is-on` : ``}`}>
            <span onClick={this.toggleMenuModal}>Menu</span>
          </div>
          */}


        </div>
      </div>
    )
  }
}

export default HeaderMenu
