import React from 'react'
import {Link} from 'react-router-dom'


class ButtonAnimated extends React.Component {

  state = {
    caption: this.props.caption,
    staticCaption: this.props.caption
  }


  enterButton = () => {
    const length = this.state.caption.length;
    let i = -2;

    this.interval = setInterval(() => {

      // One letter
      // const caption = this.state.staticCaption.substring(0,i).toLowerCase() + this.state.staticCaption.charAt(i).toUpperCase() + this.state.staticCaption.substring(i+1).toLowerCase();

      // Three letters
      const caption = this.state.staticCaption.substring(0,i).toLowerCase() + this.state.staticCaption.substring(i, i+3).toUpperCase() + this.state.staticCaption.substring(i+3).toLowerCase();

      this.setState({ caption: caption });

      i = (i === length - 1) ? -2 : i + 1;

    }, 50)

    return;
  };

  leaveButton = () => {
    clearInterval(this.interval);
    this.setState({ caption: this.state.staticCaption });
    return;
  };



  render() {
    return (

      <React.Fragment>

        <Link to={this.props.to} className={`button ${this.props.className}`} onMouseEnter={this.enterButton} onMouseLeave={this.leaveButton}>{this.state.caption}</Link>


      </React.Fragment>

    )
  }
}


export default ButtonAnimated
