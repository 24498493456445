import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'


const variants = {
    default: {
      hidden: false,
      enter: false,
      exit: false
    },
    slide: {
      hidden: { opacity: 0, x: '5%', y: 0 },
      enter: { opacity: 1, x: 0, y: 0 },
      exit: { opacity: 0, x: '-15%', y: 0 }
    },
    enterNone: {
      hidden: false,
      enter: false,
      exit: { opacity: 0, x: '-15%', y: 0 }
    },
    enterNoneExitFade: {
      hidden: false,
      enter: false,
      exit: { opacity: 0 }
    },
    none: {
      hidden: false,
      enter: false,
      exit: false
    }
}



class Page extends React.Component {

  render() {

    const variant = (this.props.variant) ? this.props.variant : 'default';

    let page;

    if (variant === 'none') {
      page = <main>{this.props.children}</main>;
    } else {
      page = <motion.main initial="hidden" animate="enter" exit="exit" variants={variants.[variant]} transition={{ type: 'linear', duration: 0.5 }} >{this.props.children}</motion.main>;
    }


    return (
      <React.Fragment>
        {page}
      </React.Fragment>
    )
  }
}


export default Page
