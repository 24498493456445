import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom' // Router
import {AppContextProvider} from './components/Context/appContext' // Context
import Modal from 'react-modal';
import App from './App'
import './css/style.scss'

const root = document.getElementById('root')

Modal.setAppElement('#root');

ReactDOM.render(<AppContextProvider><Router><App /></Router></AppContextProvider>, root);
// wrap the router around my app
