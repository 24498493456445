import React from 'react'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import {Link} from 'react-router-dom'


class MenuLink extends React.Component {

  static contextType = AppContext;

  render() {
    return (

      <AppContextConsumer>
        {context => (
          <Link to={this.props.to} onClick={() => {context.closeMenuModal();}}>
            <span className="fm-menu-title"><span>{this.props.title}</span></span>
            <span className="fm-menu-caption">{this.props.caption}</span>
          </Link>
        )}
      </AppContextConsumer>

    )
  }
}


class ModalMenu extends React.Component {

  state = {
  };



  render() {




    return (
      <React.Fragment>

        <div className="fm-menu">

          <div className="fm-menu-inner">

            <div className="fm-menu-header">
              <div className="header-title"><h4>D+D</h4></div>
              <div className="header-button"><div className="header-button-close" onClick={this.props.closeModal}>Close</div></div>
            </div>

            <div className="fm-menu-panels">
              <div class="panel panel-1">
                <div className="panel-inner">
                  <MenuLink to="/" title="Hello" caption="Welcome to Denny + Dino's wedding website" />
                </div>
              </div>

              <div class="panel panel-2">
                <div className="panel-inner">
                  <MenuLink to="/" title="RSVP" caption="Please let us know wether you are attending." />
                </div>
              </div>

              <div class="panel panel-3">
                <div className="panel-inner">
                  <MenuLink to="/" title="FAQ" caption="Some often asked questions about the festivities" />
                </div>
              </div>

              <div class="panel panel-4">
                <div className="panel-inner">
                  <MenuLink to="/" title="Schedule" caption="What happens when and where" />
                </div>
              </div>
            </div>

          </div>

        </div>

      </React.Fragment>
    )
  }
}

export default ModalMenu
