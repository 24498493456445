import React from 'react'
import {Link} from 'react-router-dom'

import locationsData from './locationsData'


const languagesData = [
  {
    key: 0,
    title: 'English',
    slug: 'en'
  },
  {
    key: 1,
    title: 'French',
    slug: 'fr'
  },
  {
    key: 2,
    title: 'Italian',
    slug: 'it'
  },
  {
    key: 3,
    title: 'Japanse',
    slug: 'ja'
  },
]



const areasData = [
  {
    key: 0,
    title: 'Europe',
    slug: 'europe'
  },
  {
    key: 1,
    title: 'Americas',
    slug: 'americas'
  },
  {
    key: 2,
    title: 'Asia',
    slug: 'asia'
  },
  {
    key: 3,
    title: 'Middle East',
    slug: 'middleeast'
  },
  {
    key: 4,
    title: 'Oceania',
    slug: 'oceania'
  },
]


class ModalCountry extends React.Component {

  state = {
    activeKey: 100,
    activeLanguage: 0,
    activeArea: false,
    filteredLocations: locationsData,
    searchTerm: false
  };


  handleChange = (event) => {

    //this.setState({ searchTerm: event.target.value });

    this.setState({ activeArea: false });

    const filteredResults = locationsData.filter(locationItem => locationItem.title.toLowerCase().includes(event.target.value.toLowerCase()));

    this.setState({ filteredLocations: filteredResults });

  }


  setActiveCountry = (activeKey) => {
    this.setState({ activeKey: activeKey });
  }


  setActiveLanguage = (activeLanguage) => {
    this.setState({ activeLanguage: activeLanguage });
  }

  setActiveArea = (activeArea, activeSlug) => {
    this.setState({ activeArea: activeArea });

    const filteredResults = locationsData.filter(locationItem => locationItem.area.includes(activeSlug));

    this.setState({ filteredLocations: filteredResults });
  }


  render() {


    const areasComponents = areasData.map(areaItem => {
      return (
        <li key={areaItem.key}>
          <div className={`area-item ${areaItem.key === this.state.activeArea ? 'is-on' : ''}`} onClick={() => this.setActiveArea(areaItem.key, areaItem.slug)}>
            <span>{areaItem.title}</span>
          </div>
        </li>
      )
    })


    const countriesComponents = this.state.filteredLocations.map(locationItem => {
      return (
        <li key={locationItem.key}>
          <div className={`country-item ${locationItem.key === this.state.activeKey ? 'is-on' : ''}`} onClick={() => this.setActiveCountry(locationItem.key)}>
            <span>{locationItem.title} {locationItem.currency}</span>
          </div>
        </li>
      )
    })



    const languagesComponents = languagesData.map(languageItem => {
      return (
        <li key={languageItem.key}>
          <div className={`language-item ${languageItem.key === this.state.activeLanguage ? 'is-on' : ''}`} onClick={() => this.setActiveLanguage(languageItem.key)}>
            <span>{languageItem.title}</span>
          </div>
        </li>
      )
    })




    return (
      <React.Fragment>
        <div className="overlay-outer-title">[L] LOCATION</div>

        <div className="widget-location">

          <div className="location-sections">

            <div className="location-section location-section-locations">

              <div className="location-header">
                <div className="location-header-search">
                  <input type="text" placeholder="Search your shipping destination" className="input-search" onChange={this.handleChange} />
                </div>
              </div>

              <div className="location-columns location-columns-locations">

                <div className="location-column location-column-areas">
                  <ul className="country-list country-list-vertical">

                    {areasComponents}

                  </ul>
                </div>

                <div className="location-column location-column-countries">
                  <ul className="country-list country-list-vertical">

                    {countriesComponents}

                  </ul>
                </div>
              </div>

            </div>


            <div className="location-section location-section-languages">

              <div className="location-header is-only-desktop">
                <div className="location-header-header">
                  Language
                </div>
              </div>

              <div className="location-columns location-columns-languages">
                <div className="location-column location-column-languages">
                  <ul className="country-list country-list-vertical">

                    {languagesComponents}

                  </ul>
                </div>
              </div>



            </div>

          </div>


          <div className="location-buttons">
            <a href="/" className="button button-large">CONFIRM</a>
          </div>

        </div>

      </React.Fragment>
    )
  }
}

export default ModalCountry
