import React from 'react'
import {Link} from 'react-router-dom'

import Typewriter from 'typewriter-effect';

class WidgetNewsletter extends React.Component {

  state = {
    step: 1,
    isTyping: false,
    isFocus: false
  }

  handleChange = (event) => {
    this.setState({ isTyping: event.target.value.length > 0 ? true : false });
  }

  goStep1 = () => { this.setState({ step: 1, isFocus: false }); }

  goStep2 = () => { this.setState({ step: 2, isFocus: true }); }

  goStep3 = () => { this.setState({ step: 3 }); }


  onFocus = (event) => {
    this.setState({ isFocus: true });
  }

  onBlur = (event) => {
    if (!this.state.isTyping && this.state.step == 1) this.setState({ isFocus: false });
  }

  render() {

    const hd = (this.props.strip) ? <span className="hd-intro"><span className="hd-intro-title">Newsletter</span> <Typewriter options={{delay: 40, deleteSpeed: 20, cursor: '', loop: true }} onInit={(typewriter) => { typewriter.pauseFor(300).typeString('New collections').pauseFor(3000).deleteAll(20).typeString('Collaborations').pauseFor(3000).deleteAll(20).typeString('Exclusive events').pauseFor(3000).deleteAll(20).typeString('Private sales').pauseFor(3000).start(); }} /></span> : <span className="hd-intro"><span className="hd-intro-title">Newsletter</span> New collections, collaborations, events and private sales</span> ;

    return (
      <div className={`widget-newsletter ${this.props.className} ${this.state.step === 2 || this.state.step === 3 ? `is-active` : ``} ${this.state.isFocus ? `is-focus` : ``}`}>
        <div className="hd">
          {/* }<span className="hd-intro" dangerouslySetInnerHTML={{ __html: this.props.intro }}></span> */}
          {hd}
          <span className={`cancel ${this.state.step === 2 ? `is-on` : ``}`} onClick={this.goStep1}>Cancel</span>
        </div>
        <div className="form">
          <div className={`form-field ${this.state.step === 1 || this.state.step === 2 ? `is-on` : ``}`}>
            <input type="text" className="input-email" placeholder="Enter your email" onChange={this.handleChange} onFocus={ this.onFocus } onBlur={ this.onBlur } />
            <button className={`button-next ${this.state.isTyping && this.state.step === 1 ? `is-on` : ``}`} onClick={this.goStep2}>{this.props.button}</button>
          </div>

          <div className={`form-field form-field-padding form-field-interested ${this.state.step === 2 ? `is-on` : ``}`}>
            <span className="interested">Interested in:</span>
            <span className="option"><input type="checkbox" id={`${this.props.className}-newsletter-woman`} />
            <label htmlFor={`${this.props.className}-newsletter-woman`}>Woman</label></span>
            <span className="option"><input type="checkbox" id={`${this.props.className}-newsletter-man`} />
            <label htmlFor={`${this.props.className}-newsletter-man`}>Man</label></span>
            <span className="option"><input type="checkbox" id={`${this.props.className}-newsletter-unisex`} />
            <label htmlFor={`${this.props.className}-newsletter-unisex`}>Unisex</label></span>
          </div>
          <div className={`form-field ${this.state.step === 2 ? `is-on` : ``}`}>
            <input type="checkbox" className="checkbox-single" id={`${this.props.className}-newsletter-confirm`} />
            <label htmlFor={`${this.props.className}-newsletter-confirm`}>I have read, understood and accepted the <Link to="/legal/termsofuse">terms and conditions</Link> and the <Link to="/legal/privacy">privacy policy</Link></label>
          </div>
          <div className={`form-field form-field-padding ${this.state.step === 2 ? `is-on` : ``}`}>
            <button className="button button-newsletter-submit" onClick={this.goStep3}>Submit</button>
          </div>
          <div className={`form-field form-field-copy form-field-padding newsletter-success ${this.state.step === 3 ? `is-on` : ``}`}>
            <p>Thank you. You have successfully been subscribed to the newsletter. We will email you shortly.</p>
            <p><button className="button" onClick={this.goStep1}>Close</button></p>
          </div>
        </div>
      </div>
    )
  }
}

export default WidgetNewsletter
