import React from 'react'
import {Link} from 'react-router-dom'
import Plx from 'react-plx'
import Typewriter from 'typewriter-effect';
import Page from '/src/components/Page/Page'
import Ticker from 'react-ticker'
import Cube from '/src/components/Cube/Cube'

import HomeIndex from './HomeIndex'

// SWIPER
import {Swiper, SwiperSlide} from 'swiper/react';
//import SwiperCore, { Autoplay, Navigation } from 'swiper';
//SwiperCore.use([Autoplay, Navigation]);

import { Autoplay, Navigation } from 'swiper/modules';

/* PLX

  More options:
  https://www.npmjs.com/package/react-plx

*/

/* TYPEWRITER

  https://www.npmjs.com/package/typewriter-effect

*/


class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      scrollRatio: 5,
      test: "test state"
    }
  }


  componentDidMount() {
    document.body.classList.add('is-home');
  }

  componentWillUnmount() {
    document.body.classList.remove('is-home');
  }

  render() {


    return (
      <Page>


        <div className="site-grid site-grid-home">



          <div className="item item-symbol">
            <div className="item-inner">
              <img src="/img/dd/logo-dd-240505.svg" />
            </div>
          </div>

          <div className="item item-text">
            <div className="item-inner">
              <p>Dear friends,<br /><br />You are cordially invited to attend the wedding ceremony and celebration of Dino Pettinaroli and Denny Backhaus, taking place on Saturday, August 31, 2024, at 15:30hs, on the premises of the <i>Amstel Hotel</i> in Amsterdam, The Netherlands.</p>

              <p><br />
                <center>
                  Saturday<br />
                  August 31, 2024<br />
                  15:30hs
                </center>
              </p>

              <p><br />
                <center>
                  <a href="https://maps.app.goo.gl/acrHfmCFQUWeAFAc6" target="_blank">Amstel Hotel<br />
                  Prof. Tulpplein 1<br />
                  Amsterdam</a>
                </center>
                <br />
              </p>

              <p>We are looking forward to celebrating our love together with you.</p>

              <p>With Love,<br />
              Denny + Dino
              </p>

            </div>
          </div>



          <div className="item item-text item-text-faq">
            <div className="item-inner">
              <p><center><em>The Chongos&rsquo; F.A.Q.</em></center></p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">What is the dress code?</p>
              <p>Formal Attire Eleganza Extravaganza</p>
              <p>A tuxedo isn&rsquo;t required, but can still be worn if preferred. If opting for a suit, however, a formal suit in any color and optional tie are acceptable. The outfit choices could vary from floor-length gown, a fancy cocktail dress, or a nice pantsuit. It&rsquo;s Eleganza Extravaganza, so over the top fabulous is encouraged. High amounts of exceptional elegance are welcome. Ultimately, dress however makes you feel most comfortable.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">Can I bring a date?</p>
              <p>Please check your invite for your +1.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">Are children welcome?</p>
              <p>As much as we love your children, we will not be including them in the event. Please reach out if you have questions.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">What if I have a dietary restriction?</p>
              <p>Please let us know on your RSVP if you have any dietary restrictions and we&rsquo;ll make sure to accommodate you.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">How do I get to the Amstel Hotel?</p>
              <p>You can take Uber, metered taxis, boat, or tram and metro stopping at Weesperplein.</p>
              <p>If you prefer, Uber and metered taxis are available in Amsterdam. We recommend you to schedule an Uber/taxi pick up in advance. You could additionally ask your hotel concierge to schedule a taxi for you.</p>

              <p>Address:<br />
              <a href="https://maps.app.goo.gl/acrHfmCFQUWeAFAc6" target="_blank">Amstel Hotel<br />
              Prof. Tulpplein 1<br />
              Amsterdam</a></p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">If I go by car, where should I park?</p>
              <p>Please contact us as soon as you know you will go by car, so we can book you a parking spot at the venue. The hotel has limited parking availability, so we need to book a parking space as soon as possible. Otherwise, you can park anywhere around the area.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">Where is the after party?</p>
              <p>The after party will be held at Bar Lempicka, across the street from the Amstel Hotel.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">Is there transportation being provided between the wedding venue and the after party?</p>
              <p>No transportation between the two venues is needed, as they are located in the same street.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">What will the weather be like?</p>
              <p>Well, the wedding is in Amsterdam. Good luck with getting an answer on this. </p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">Is the wedding indoors or outdoors?</p>
              <p>The wedding ceremony and the reception will happen outdoors, meaning we will be on a terrace overlooking the Amstel river until 7:00pm. The dinner and party will happen indoors. In case of rain, all festivities will be held indoors.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">What kind of shoes should I wear?</p>
              <p>The whole night will be on paved floor and carpet. There will not be any grass or gravel floors. Therefore, in line with the dress code, any kind of footwear is welcome.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">What time should I arrive?</p>
              <p>The celebration ceremony starts at 16:00 hs. It&rsquo;s recommended to arrive at 15:30 hs, before the ceremony starts.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">Is there a gifts registry?</p>
              <p>We&rsquo;ve decided not to do a gift registry but if you feel inclined to give a gift, we will happily accept a contribution to our honeymoon.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">Will there be dancing?</p>
              <p>Yas queen, absolutely.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">I have a question not answered here; how do I contact you?</p>
              <p>Feel free to email Dino at <a href="mailto:dinopettinaroli@gmail.com">dinopettinaroli@gmail.com</a> or Denny at <a href="mailto:dennybackhaus@gmail.com">dennybackhaus@gmail.com</a>, or just text us.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q"></p>
              <p></p>
            </div>
          </div>





        </div>



      </Page>
    )
  }
}

export default Home
