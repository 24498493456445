import React from 'react'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import {Link} from 'react-router-dom'
import {isMobile} from 'react-device-detect'
import HeaderLink from './HeaderLink'
import HeaderIconBack from './HeaderIconBack'


class HeaderNavExplore extends React.Component {

  static contextType = AppContext;


  render() {

    return (
      <nav
        id="nav-explore"
        className={`nav nav-primary ${(this.context.state.activeNav === 'explore') ? `is-on` : ``}  ${(this.context.state.activeNavMobile === 'explore') ? `is-on-mobile` : ``} ${(this.context.state.mobileMenuIsClosing === 'explore') ? `is-closing-mobile` : ``}`}
        onMouseEnter={isMobile ? false : () => this.context.setActiveNavInner('explore')}
        onMouseLeave={isMobile ? false : this.context.unsetActiveNavInner}
        >

        <div className="nav-layout nav-layout-horizontal">


          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">Stores</li>
              <li><HeaderLink to="/stores" title="Store locator" /></li>
              <li><HeaderLink to="/stores" title="New stores" /></li>
              <li><HeaderLink to="/stores" title="Stockholm flagship" /></li>
            </ul>
          </div>

          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">Collections</li>
              <li><HeaderLink to="/explore/lookbook/aw22" title="AW22 Runway" /></li>
              <li><HeaderLink to="/explore/lookbook/ss22" title="SS22 Runway" /></li>
              <li><HeaderLink to="/explore/lookbook/ss22" title="SS22 Lookbook" /></li>
            </ul>
          </div>

          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">Sustainability</li>
              <li><HeaderLink to="/explore/sustainability" title="Our Mission" /></li>
              <li><HeaderLink to="/explore/sustainability/impacts" title="Impacts" /></li>
              <li><HeaderLink to="/explore/sustainability/traceability" title="Traceability" /></li>
              <li><HeaderLink to="/explore/sustainability/circularity" title="Circularity" /></li>
            </ul>
          </div>

          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">Projects</li>
              <li><HeaderLink to="/" title="Swedish wool" /></li>
              <li><HeaderLink to="/" title="Swedish leather" /></li>
            </ul>
          </div>

          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">About</li>
              <li><HeaderLink to="/explore/about" title="Denny & Dino Heritage" /></li>
            </ul>
          </div>


          <HeaderIconBack />

        </div>
      </nav>

    )
  }
}

export default HeaderNavExplore
