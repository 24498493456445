import React from 'react'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import {Link} from 'react-router-dom'
import {isMobile} from 'react-device-detect'
import HeaderLink from './HeaderLink'
import ButtonAnimated from '/src/components/Forms/ButtonAnimated'


class HeaderNavBag extends React.Component {

  static contextType = AppContext;

  state = {
    isAddingNippleBox: false,
    promoCodeActive: false
  }


  handleChange = (event) => {
    const {name, value, type, checked} = event.target;
    type === 'checkbox' ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
  }

  doRemove = () => {
    alert('This item has been removed from bag');
  }

  doPlus = () => {
    alert('One more has been added');
  }

  doMinus = () => {
    alert('One has been removed');
  }

  showPromoCode = () => {
    this.setState({ promoCodeActive: true });
  }


  render() {

    return (
      <nav
        id="nav-bag"
        className={`nav nav-secondary ${(this.context.state.activeNav === 'bag') ? `is-on` : ``}  ${(this.context.state.activeNavMobile === 'bag') ? `is-on-mobile` : ``} ${(this.context.state.mobileMenuIsClosing === 'bag') ? `is-closing-mobile` : ``}`}
        onMouseEnter={isMobile ? false : () => this.context.setActiveNavInner('bag')}
        onMouseLeave={isMobile ? false : this.context.unsetActiveNavInner}
        >

        <div className="nav-layout">



          <div className="site-system site-system-minibag">

            <div className={`item item-product item-product-minibag ${(this.context.state.isAddedToBag) ? `is-addedtobag` : ``}`}>
              <div className="image"><Link to="/pdp"><img src="/uploads/products/october2021/flat-1.jpg" /></Link></div>
              <div className="info">
                <div className="caption">
                  <div className="title"><span className="title-inner">Aurelia Lurex Top</span> <span className="title-favorite">Favorite</span></div>
                  <div className="values">
                    <span className="value-size">
                      {/*
                      <span className="value-size-selector">
                        <select>
                          <option>Size 34</option>
                          <option>Size 36</option>
                          <option>Size 38</option>
                          <option>Size 40</option>
                          <option>Size 42</option>
                        </select>
                      </span>
                      */}
                      Size 36
                    </span>
                    <span className="value-material">Sand</span>
                    <span className="value-other"></span>
                  </div>
                  <div className="info-price">&euro;700.00 &times; 1</div>
                  <div className="tools">
                    <div className="tool tool-quantity">
                      <div className="quantity">&times; <span className="quantity-value">1</span></div>
                      <div className="quantity-modifiers">
                        <span className="icon-quantity icon-quantity-minus" onClick={this.doRemove}>-</span>
                          <span className="icon-quantity icon-quantity-amount">2</span>
                          <span className="icon-quantity icon-quantity-plus" onClick={this.doPlus}>+</span>
                      </div>
                    </div>
                    <div className="tool tool-remove"><a href="#" className="link-remove" onClick={this.doRemove}><span>Remove</span></a></div>
                  </div>
                </div>
                <div className="price">
                  <div className="tools">
                    <div className="tool tool-quantity">
                      <div className="quantity">&times; <span className="quantity-value">1</span></div>
                      <div className="quantity-modifiers">
                        <span className="icon-quantity icon-quantity-minus" onClick={this.doRemove}>-</span>
                          <span className="icon-quantity icon-quantity-amount">1</span>
                          <span className="icon-quantity icon-quantity-plus" onClick={this.doPlus}>+</span>
                      </div>
                    </div>
                  </div>
                  <div className="price-amount">1 &times;</div>
                  <div className="price-price">&euro;700.00</div>
                </div>
              </div>
            </div>



            <div className="item item-product item-product-minibag">
              <div className="image"><Link to="/pdp"><img src="/uploads/products/october2021/flat-2.jpg" /></Link></div>
              <div className="info">
                <div className="caption">
                  <div className="title"><span className="title-inner">Cotton Stretch Sleeve</span> <span className="title-favorite">Favorite</span></div>
                  <div className="values">
                    <span className="value-size"></span>
                    <span className="value-material">Marron</span>
                    <span className="value-other"></span>
                  </div>
                  <div className="info-price">&euro;500.00 &times; 2</div>
                  <div className="tools">
                    <div className="tool tool-quantity">
                      <div className="quantity">&times; <span className="quantity-value">2</span></div>
                      <div className="quantity-modifiers">
                      <span className="icon-quantity icon-quantity-minus" onClick={this.doMinus}>-</span>
                      <span className="icon-quantity icon-quantity-amount">2</span>
                        <span className="icon-quantity icon-quantity-plus" onClick={this.doPlus}>+</span>
                      </div>
                    </div>
                    <div className="tool tool-remove"><a href="#" className="link-remove" onClick={this.doRemove}><span>Remove</span></a></div>
                  </div>
                </div>
                <div className="price">
                  <div className="tools">
                    <div className="tool tool-quantity">
                      <div className="quantity">&times; <span className="quantity-value">2</span></div>
                      <div className="quantity-modifiers">
                        <span className="icon-quantity icon-quantity-minus" onClick={this.doMinus}>-</span>
                        <span className="icon-quantity icon-quantity-amount">2</span>
                        <span className="icon-quantity icon-quantity-plus" onClick={this.doPlus}>+</span>
                      </div>
                    </div>
                  </div>
                  <div className="price-amount">2 &times;</div>
                  <div className="price-price">&euro;500.00</div>
                </div>
              </div>
            </div>


            <div className={`item item-total item item-total-promo item-total-first`}>
              <div className="caption">
                <div className={`caption-option ${this.state.promoCodeActive ? 'is-off' : ''}`} onClick={this.showPromoCode}>+ Add promotion code</div>

                <div className={`caption-option ${this.state.promoCodeActive ? '' : 'is-off'}`}>
                  <input type="text" placeholder="Enter promotion code" className="input-promo" />
                  <button className="button-promo">OK</button>
                </div>

              </div>

            </div>


            <div className="item item-total item-total item-total-first">
              <div className="caption">Free shipping above €100</div>
              {/* <div className="progressbar"><div className="progressbar-inner"><div className="progressbar-active"></div></div></div> */}
            </div>

            <div className="item item-total">
              <div className="caption">TOTAL (taxes &amp; duties incl.)</div>
              <div className="price">$5300.00</div>
            </div>


            <div className="item item-button item-button-sticky item-button-checkout">

              <ButtonAnimated to="/checkout" caption="CHECKOUT" className="button-xlarge button-checkout" />


              {/*
              <HeaderLink to="/checkout" title="Checkout" customClassName={`button button-xlarge button-inverted ${(this.context.state.isAddedToBag) ? `is-small` : ``}`} />
              */}


              <button className={`button button-xlarge ${(this.context.state.isAddedToBag) ? `is-on` : `is-off`}`} onClick={this.context.closeBag}>Continue shopping</button>
            </div>



            <div className="item item-listing">
              <ul className="listing-payment">
                <li><img src="/img/icon-payment-2021-visa.svg" alt="Visa" /></li>
                <li><img src="/img/icon-payment-2021-mastercard.svg" alt="MasterCard" /></li>
                <li><img src="/img/icon-payment-2021-amex.svg" alt="American Express" /></li>
                <li><img src="/img/icon-payment-2021-diners.svg" alt="Diners Club" /></li>
                <li><img src="/img/icon-payment-2021-klarna.svg" alt="Klarna" /></li>
                <li><img src="/img/icon-payment-2021-paypal.svg" alt="PayPal" /></li>
                {/*
                <li><img src="/img/icon-payment-2021-apple.svg" alt="ApplePay" /></li>
                */}
                {/*
                <li><img src="/img/icon-payment-2021-alipay.svg" alt="AliPay" /></li>
                */}
              </ul>
            </div>

          </div>

        </div>
      </nav>

    )
  }
}

export default HeaderNavBag
