import React from 'react';
const AppContext = React.createContext();

class AppContextProvider extends React.Component {

  state = {
    theme: 'dark',
    bagAmount: 0,
    isAddingToBag: false,
    isAddedToBag: false,
    menuIsOpen: false,
    menuIsForceClosed: false,

    mobileMenuIsOpen: false,
    mobileMenuIsClosing: false,
    activeNav: false,
    activeNavMobile: false,
    isInNav: false,
    isInHeader: false,
    searchIsOpen: false,
    searchTerm: false,

    pageTitle: false,
    modalCountryIsOpen: false,
    modalMenuIsOpen: false,
    modalPromoBannerIsOpen: false,

    fmNavLevel1: false,
    fmNavLevel2: false,
  }

  addToBag = () => {
    this.setState({ isAddingToBag: true })

    setTimeout(() => {

      this.setActiveNav('bag');
      this.setActiveNavMobile('bag');
      this.setState({ isAddedToBag: true });

      this.setState(prevState => {
        return {
          isAddingToBag: false,
          bagAmount: prevState.bagAmount + 1
        }
      });

    }, 1500)
  }

  closeBag = () => {
    this.forceUnsetActiveNav();
    this.unsetActiveNavMobile();
  }


  toggleSearch = () => {
    this.setState(prevState => ({ searchIsOpen: !prevState.searchIsOpen }));
  }

  openSearch = () => {
    this.setState({ searchIsOpen: true });
  }

  closeSearch = () => {
    this.setState({ searchIsOpen: false });
  }

  setSearchTerm = (searchTerm) => {
    this.setState({ searchTerm: searchTerm });
  }

  addPageTitle = (pageTitle) => {
    this.setState({ pageTitle: pageTitle });
  }

  removePageTitle = () => {
    this.setState({ pageTitle: false });
  }

  openCountryModal = () => {
    this.setState({ modalCountryIsOpen: true });
  }

  closeCountryModal = () => {
    this.setState({ modalCountryIsOpen: false });
  }

  openMenuModal = () => {
    this.setState({ modalMenuIsOpen: true });
  }

  closeMenuModal = () => {
    this.setState({ modalMenuIsOpen: false });
  }

  toggleMenuModal = () => {
    this.setState(prevState => ({ modalMenuIsOpen: !prevState.modalMenuIsOpen }));
  }

  openPromoBannerModal = () => {
    this.setState({ modalPromoBannerIsOpen: true });
  }

  closePromoBannerModal = () => {
    this.setState({ modalPromoBannerIsOpen: false });
  }




  /* New header behaviour */

  openMenuMobile = () => {
    document.getElementsByTagName('html')[0].classList.add('is-disabled');
    this.closeSearch();

    this.setState({ mobileMenuIsOpen: true });
  }

  closeMenuMobile = () => {
    document.getElementsByTagName('html')[0].classList.remove('is-disabled');

    if (this.state.activeNavMobile) this.setState({ mobileMenuIsClosing: this.state.activeNavMobile });

    this.setState({ mobileMenuIsOpen: false, activeNavMobile: false });

    setTimeout(() => {
      this.setState({ mobileMenuIsClosing: false });
    }, 800);
  }

  setActiveNav = (nav) => {
    this.setState({ activeNav: nav, isInNav: false, isInHeader: true });

    setTimeout(() => {
      this.setState({ isInHeader: false });
    }, 20);
  }

  unsetActiveNav = () => {
    setTimeout(() => {
      if (!this.state.isInNav && !this.state.isInHeader) this.setState({ activeNav: false });
    }, 10);
    setTimeout(() => { this.setState({ isAddedToBag: false }); }, 1500);
  }

  forceUnsetActiveNav = () => {
    this.setState({ activeNav: false, isInNav: false });
    this.closeMenuMobile();
    setTimeout(() => { this.setState({ isAddedToBag: false }); }, 1500);
  }

  setActiveNavInner = (nav) => {
    this.setState({ activeNav: nav, isInNav: true });
  }

  unsetActiveNavInner = () => {
    this.setState({ activeNav: false, isInNav: false });
  }

  setActiveNavMobile = (nav) => {
    this.setState({ activeNavMobile: nav });
    this.closeSearch();
  }

  unsetActiveNavMobile = () => {
    this.setState({ activeNavMobile: false });
    setTimeout(() => { this.setState({ isAddedToBag: false }); }, 1500);
  }


  // FM

  setPageType = (value1, value2) => {
    this.setState({ fmNavLevel1: value1 ? value1 : false, fmNavLevel2: value2 ? value2 : false });
  }


  // We can return our functions with value as an object
  render() {
    return (
      <AppContext.Provider value={{
        state: this.state,
        addToBag: this.addToBag,
        closeBag: this.closeBag,
        toggleSearch: this.toggleSearch,
        openSearch: this.openSearch,
        closeSearch: this.closeSearch,
        setSearchTerm: this.setSearchTerm,
        addPageTitle: this.addPageTitle,
        removePageTitle: this.removePageTitle,
        openCountryModal: this.openCountryModal,
        closeCountryModal: this.closeCountryModal,
        openMenuModal: this.openMenuModal,
        closeMenuModal: this.closeMenuModal,
        toggleMenuModal: this.toggleMenuModal,
        openPromoBannerModal: this.openPromoBannerModal,
        closePromoBannerModal: this.closePromoBannerModal,
        setActiveNav: this.setActiveNav,
        unsetActiveNav: this.unsetActiveNav,
        forceUnsetActiveNav: this.forceUnsetActiveNav,
        setActiveNavInner: this.setActiveNavInner,
        unsetActiveNavInner: this.unsetActiveNavInner,
        setActiveNavMobile: this.setActiveNavMobile,
        unsetActiveNavMobile: this.unsetActiveNavMobile,
        openMenuMobile: this.openMenuMobile,
        closeMenuMobile: this.closeMenuMobile,
        setPageType: this.setPageType
      }}>
        {this.props.children}
      </AppContext.Provider>
    )
  }

}
const AppContextConsumer = AppContext.Consumer;
export {AppContext, AppContextProvider, AppContextConsumer};
