import React from 'react'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import {Link} from 'react-router-dom'
import {isMobile} from 'react-device-detect'
import HeaderLink from './HeaderLink'
import HeaderIconBack from './HeaderIconBack'


class HeaderNavProjects extends React.Component {

  static contextType = AppContext;

  render() {

    return (
      <nav
        id="nav-projects"
        className={`nav nav-primary ${(this.context.state.activeNav === 'projects') ? `is-on` : ``}  ${(this.context.state.activeNavMobile === 'projects') ? `is-on-mobile` : ``} ${(this.context.state.mobileMenuIsClosing === 'projects') ? `is-closing-mobile` : ``}`}
        onMouseEnter={isMobile ? false : () => this.context.setActiveNavInner('projects')}
        onMouseLeave={isMobile ? false : this.context.unsetActiveNavInner}
        >

        <div className="nav-layout nav-layout-horizontal">

          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">New</li>
              <li><HeaderLink to="/project/1" title="New in" /></li>
              <li><HeaderLink to="/project/2" title="Pre-order" /></li>
              <li><HeaderLink to="/project/3" title="Coming soon" /></li>
            </ul>
            <ul className="nav-menu is-only-desktop">
              <li className="hd divider">Featured</li>
              <li><HeaderLink to="/project/1" title="Soft Sport" /></li>
              <li><HeaderLink to="/project/2" title="Remade" /></li>
              <li><HeaderLink to="/project/3" title="Limited edition" /></li>
              <li><HeaderLink to="/project/4" title="Preowned" /></li>
              <li><HeaderLink to="/project/5" title="Archive" /></li>
            </ul>
          </div>

          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">Ready to Wear</li>
              <li className="nav-woman-outerwear"><HeaderLink to="/woman/outerwear" title="Outerwear" /></li>
              <li><HeaderLink to="/project/1" title="Blazers" /></li>
              <li><HeaderLink to="/project/2" title="Sweaters" /></li>
              <li><HeaderLink to="/project/3" title="Shirts" /></li>
              <li><HeaderLink to="/project/4" title="Tops" /></li>
              <li><HeaderLink to="/project/5" title="Trousers" /></li>
              <li><HeaderLink to="/project/6" title="Jeans" /></li>
              <li><HeaderLink to="/project/7" title="Dresses" /></li>
              <li><HeaderLink to="/project/8" title="Skirts" /></li>
              <li><HeaderLink to="/project/9" title="Swim" /></li>
              <li><HeaderLink to="/project/10" title="Leggins" /></li>
              <li><HeaderLink to="/project/11" title="Sports bras" /></li>
              <li><HeaderLink to="/project/12" title="View all" /></li>
            </ul>
          </div>

          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">Accessories</li>
              <li><HeaderLink to="/project/1" title="Shoes" /></li>
              <li><HeaderLink to="/project/2" title="Bags" /></li>
              <li><HeaderLink to="/project/3" title="Small leather goods" /></li>
              <li><HeaderLink to="/project/4" title="Hats & gloves" /></li>
              <li><HeaderLink to="/project/5" title="Other accessories" /></li>
              <li><HeaderLink to="/project/6" title="View all" /></li>
            </ul>
          </div>

          <div className="nav-layout-item nav-layout-item-3-of-16 is-only-mobile">
            <ul className="nav-menu">
              <li className="hd">Featured</li>
              <li><HeaderLink to="/project/1" title="Soft Sport" /></li>
              <li><HeaderLink to="/project/2" title="Remade" /></li>
              <li><HeaderLink to="/project/3" title="Limited edition" /></li>
              <li><HeaderLink to="/project/4" title="Preowned" /></li>
              <li><HeaderLink to="/project/5" title="Archive" /></li>
            </ul>
          </div>


          <HeaderIconBack />

        </div>
      </nav>

    )
  }
}

export default HeaderNavProjects
