import React from 'react'
import {Link} from 'react-router-dom'
import {AppContext} from '/src/components/Context/appContext'
import Modal from 'react-modal';
import ModalCountry from '/src/components/Modal/ModalCountry'
import WidgetNewsletter from '../Newsletter/WidgetNewsletter'



class Cube extends React.Component {

  static contextType = AppContext;

  state = {
    isOpen: false
  };





  render() {






    return (

      <div className="lv-cube">

        <section className="cube-container">
          <div className="cube cube-rotating cube-blue">
            <figure className="front">front</figure>
            <figure className="back">back</figure>
            <figure className="right">right</figure>
            <figure className="left">left</figure>
            <figure className="top">top</figure>
            <figure className="bottom">bottom</figure>
          </div>
        </section>

      </div>

    )
  }
}


export default Cube
