import React from 'react'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'


class HeaderIconBack extends React.Component {

  static contextType = AppContext;


  render() {

    return (

      <div className="nav-icon-back" onClick={this.context.unsetActiveNavMobile}>
        <svg viewBox="0 0 210 210">

          <path className="path" fill="transparent" stroke="black" strokeWidth="10"
            d="M 10,105 L200,105" />

          <path className="path path-b" fill="transparent" stroke="black" strokeWidth="10"
            d="M 105,10 L10,105" />

          <path className="path path-c" fill="transparent" stroke="black" strokeWidth="10"
            d="M 10,105 L105,200" />

        </svg>
      </div>

    )
  }
}

export default HeaderIconBack
