import React from 'react'
import {Link} from 'react-router-dom'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'

class HeaderMobile extends React.Component {

  static contextType = AppContext;

  render() {
    return (
      <div className={`header-mobile ${(this.context.state.mobileMenuIsOpen || this.context.state.activeNavMobile === 'bag') ? `is-off` : ``} ${(this.context.state.searchIsOpen) ? `is-transparent-mobile` : ``}`}>
        <ul className="header-mobile-icons">

          <li className="header-mobile-icon header-mobile-icon-logotype"><Link to="/">Denny & Dino</Link></li>

          <AppContextConsumer>
            {context => (
              <li className="header-mobile-icon header-mobile-icon-menu" onClick={context.openMenuMobile}>Menu</li>
            )}
          </AppContextConsumer>


          <AppContextConsumer>
            {context => (
              <li className="header-mobile-icon header-mobile-icon-search" onClick={context.toggleSearch}>Search</li>
            )}
          </AppContextConsumer>


          <AppContextConsumer>
            {context => (
              <li className={`header-mobile-icon header-mobile-icon-bag ${context.state.bagAmount > 0 && 'has-items'}`} onClick={() => this.context.setActiveNavMobile('bag')}>Bag <span className="amount">{context.state.bagAmount}</span></li>
            )}
          </AppContextConsumer>
        </ul>
      </div>
    )
  }
}

export default HeaderMobile
