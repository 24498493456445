import React from 'react'
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import {Link} from 'react-router-dom'
import {isMobile} from 'react-device-detect'
import HeaderLink from './HeaderLink'
import HeaderIconBack from './HeaderIconBack'


class HeaderNavAbout extends React.Component {

  static contextType = AppContext;


  render() {

    return (
      <nav
        id="nav-man"
        className={`nav nav-primary ${(this.context.state.activeNav === 'about') ? `is-on` : ``}  ${(this.context.state.activeNavMobile === 'about') ? `is-on-mobile` : ``} ${(this.context.state.mobileMenuIsClosing === 'about') ? `is-closing-mobile` : ``}`}
        onMouseEnter={isMobile ? false : () => this.context.setActiveNavInner('about')}
        onMouseLeave={isMobile ? false : this.context.unsetActiveNavInner}
        >

        <div className="nav-layout nav-layout-horizontal">

          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">New</li>
              <li><HeaderLink to="/woman/newarrivals" title="New in" /></li>
              <li><HeaderLink to="/woman/remade" title="Pre-order" /></li>
              <li><HeaderLink to="/woman/limitededition" title="Coming soon" /></li>
            </ul>
            <ul className="nav-menu is-only-desktop">
              <li className="hd divider">Featured</li>
              <li><HeaderLink to="/woman/remade" title="Remade" /></li>
              <li><HeaderLink to="/woman/limitededition" title="Limited edition" /></li>
              <li><HeaderLink to="/woman/remade" title="Preowned" /></li>
              <li><HeaderLink to="/woman/remade" title="Archive" /></li>
            </ul>
          </div>

          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">Ready to Wear</li>
              <li><HeaderLink to="/woman/outerwear" title="Outerwear" /></li>
              <li><HeaderLink to="/woman/outerwear" title="Suit jackets" /></li>
              <li><HeaderLink to="/woman/outerwear" title="Trousers" /></li>
              <li><HeaderLink to="/woman/outerwear" title="Jeans" /></li>
              <li><HeaderLink to="/woman/outerwear" title="Knitwear" /></li>
              <li><HeaderLink to="/woman/outerwear" title="Sweatshirts" /></li>
              <li><HeaderLink to="/woman/outerwear" title="Shirts" /></li>
              <li><HeaderLink to="/woman/outerwear" title="T-Shirts" /></li>
              <li><HeaderLink to="/woman/outerwear" title="View all" /></li>
            </ul>
          </div>

          <div className="nav-layout-item nav-layout-item-3-of-16">
            <ul className="nav-menu">
              <li className="hd">Accessories</li>
              <li><HeaderLink to="/woman/outerwear" title="Shoes" /></li>
              <li><HeaderLink to="/woman/outerwear" title="Bags" /></li>
              <li><HeaderLink to="/woman/outerwear" title="Small leather goods" /></li>
              <li><HeaderLink to="/woman/outerwear" title="Hats & gloves" /></li>
              <li><HeaderLink to="/woman/outerwear" title="Other accessories" /></li>
              <li><HeaderLink to="/woman/outerwear" title="View all" /></li>
            </ul>
          </div>

          <div className="nav-layout-item nav-layout-item-3-of-16 is-only-mobile">
            <ul className="nav-menu">
              <li className="hd">Featured</li>
              <li><HeaderLink to="/woman/remade" title="Remade" /></li>
              <li><HeaderLink to="/woman/limitededition" title="Limited edition" /></li>
              <li><HeaderLink to="/woman/remade" title="Preowned" /></li>
              <li><HeaderLink to="/woman/remade" title="Archive" /></li>
            </ul>
          </div>


          <HeaderIconBack />

        </div>
      </nav>

    )
  }
}

export default HeaderNavAbout
